import React, {Fragment,useEffect, useRef,  useState, useContext} from 'react';
import { createPortal } from 'react-dom';
import Recaptcha from 'react-recaptcha';
import axios from 'axios'
import { toast } from 'react-toastify';
import MenuContext from '../contexts/MenuContext';

const Contact = (props) => {

    const myMap = useRef();
    const myform = useRef();

    const [fields, setFields] = useState({
        "prenom": "",
        "nom": "",
        "mail": "",
        "message": ""
    });
    const [errors, setErrors] = useState([]);
    const [captcha, setCaptcha] = useState(false);
    const {showtimeOn, setMenuSelect} = useContext(MenuContext)
    const [recaptchaRendered, setRecaptchaRendered] = useState(false)

    useEffect(()=>{
        if (!recaptchaRendered) {
            setRecaptchaRendered(true);
        }
    },[recaptchaRendered])

    useEffect(()=>{
        window.scrollTo(0,0)
        setMenuSelect('contact')
    },[])

    
    const handleClick = (e) => {

        console.log(myMap.current.attributes.src.value)

        myMap.current.attributes.src.value="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d10109.466080470052!2d4.023379847699557!3d50.694582672155505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e2!4m5!1s0x47c3b17c70a6deab%3A0xf407495e2791f2cd!2sEnghien%2C%207850%20Enghien!3m2!1d50.6970878!2d4.0470023!4m5!1s0x47c3b220b64a2ac5%3A0xd7c2f2b388cda45d!2sRue%20du%20Village%2050%2C%207850%20Enghien!3m2!1d50.6921197!2d4.0178161999999995!5e0!3m2!1sfr!2sbe!4v1599375967339!5m2!1sfr!2sbe" 
    }

    const handleChange = (field ,event) => {
        const {name, value} = event.currentTarget
        setFields({...fields, [name]: value})
       
    }
   

    const handleValidation = () => {
        let error = {};
        let formIsValidPrenom = true;
        let formIsValidNom = true;
        let formIsValidMail = true;
        let formIsValidMessage = true;

        if(!fields.prenom){
            formIsValidPrenom = false;
            error['prenom'] = "Veuillez remplir le champ 'Prénom'"
        }else{
            formIsValidPrenom = true;
            error['prenom'] = ""
        }

        if(!fields.nom){
            formIsValidNom = false;
            error['nom'] = "Veuillez remplir le champ 'nom'"
        }else{
            formIsValidNom = true;
            error['nom'] = ""
        }

        if(!fields.mail){
            formIsValidMail = false;
            error['mail'] = "Veuillez remplir le champ 'mail' avec une adresse valide"
        }else{
            formIsValidMail = true;
            error['mail'] = ""
        }

        if(!fields.message){
            formIsValidMessage = false;
            error['message'] = "Veuillez remplir le champ 'message'"
        }else{
            formIsValidMessage = true;
            error['message'] = ""
        }

        setErrors(error)
        if(formIsValidPrenom && formIsValidNom && formIsValidMail && formIsValidMessage) {
            return true
        }else{
            return false
        }
    }

    const verifyCallback = (response) => {
        if(response){
            setCaptcha(true)
        }
    }

    const recaptchaLoaded = () => {
        //console.log('Done!!!!');
    }

    const contactSubmit = async (event)=>{
        event.preventDefault()
        if(handleValidation()){
            let error =[]
            if(captcha){
             
                try{
                    axios.post("https://adminepse.jordanberti.com/api/contacts/mailer",fields)
                    toast.info('Votre message à bien été envoyé')
                    setFields({
                        "prenom": "",
                        "nom": "",
                        "mail": "",
                        "message": ""
                    })
                }catch(error){
                    //
                }  
            }else{
               error['captcha'] = "Veuillez cocher le captcha"
            }
            setErrors(error) 
        }else{
            //console.log('error')
        }
    }

    return ( 
        <Fragment>
            <div className={"page-home" + (showtimeOn==="yes" ? " sto" : "")}>
                <div className="container">
                    <h1>CONTACT</h1>
                    <p>Pour toute information complémentaire, n'hésitez pas à nous téléphoner au <strong>02/395.60.23</strong> ou à nous envoyer le formulaire ci-dessous via courriel</p>
                </div>
            </div>
            <div id="contact-body">
                <div className="container">
                    <div id="contact-form">
                        <form ref={myform} action="https://adminepse.jordanberti.com" method="post" onSubmit={contactSubmit}>
                            <div className="form-group">
                                <input type="text" value={fields.prenom} placeholder="Prénom" onChange={(e)=>handleChange("prenom",e)} name="prenom"/>
                                <div className="form-error">{errors.prenom}</div>
                            </div>
                            <div className="form-group">
                                <input type="text" value={fields.nom} placeholder="Nom" name="nom" onChange={(e)=>handleChange("nom",e)}/>
                                <div className="form-error">{errors.nom}</div>
                            </div>
                            <div className="form-group">
                                <input type="email" value={fields.mail} placeholder="Adresse e-mail" name="mail" onChange={(e)=>handleChange("mail",e)}/>
                                <div className="form-error">{errors.mail}</div>
                            </div>
                            <div className="form-group">
                                <textarea name="message" id="message" cols="30" rows="10" placeholder="Votre message"  value={fields.message} onChange={(e)=>handleChange("message",e)}/>
                                <div className="form-error form-error-last">{errors.message}</div>
                            </div>
                            <div className="form-error form-error-last">{errors.captcha}</div>
                            <div className="btn-container">
                                <input type="submit" value="ENVOYER"/>
                                <div id="captcha">
                                    <Recaptcha
                                        sitekey="6LddDvwUAAAAABBDaIM6wiTrGA_VW_33FEsHIc75"
                                        render="explicit" 
                                        verifyCallback={verifyCallback}
                                        onloadCallback={recaptchaLoaded}
                                        hl="fr"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div id="ouverture">
                        <h2>HEURES D'OUVERTURE DU SECRÉTARIAT</h2>
                        <div id="academique">
                            <h3>ANNÉE ACADÉMIQUE</h3>
                            <p>Durant l'année académique (28 août 2023 - 05 juillet 2024) en journée, jusqu'au <strong>22 juin</strong> inclus en soirée, le secrétariat de l'établissement assure une permanence aux jours et heures renseignés ci-dessous :</p>
                            <table className="table table-contact align-center">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Lundi</th>
                                        <th>Mardi</th>
                                        <th>Mercredi</th>
                                        <th>Jeudi</th>
                                        <th>Vendredi</th>
                                    </tr>
                                </thead>
                                <tbody className="body-calendar">
                                    <tr>
                                        <td>08h15 - 12h00</td>
                                        <td><i className="fas fa-check"></i></td>
                                        <td><i className="fas fa-check"></i></td>
                                        <td><i className="fas fa-check"></i></td>
                                        <td><i className="fas fa-check"></i></td>
                                        <td><i className="fas fa-check"></i></td>
                                    </tr>
                                    <tr>
                                        <td>12h00 - 16h00</td>
                                        <td><i className="fas fa-check"></i></td>
                                        <td><i className="fas fa-check"></i></td>
                                        <td> </td>
                                        <td><i className="fas fa-check"></i></td>
                                        <td><i className="fas fa-check"></i></td>
                                    </tr>
                                    <tr>
                                        <td>18h25 - 20h30</td>
                                        <td><i className="fas fa-check"></i></td>
                                        <td><i className="fas fa-check"></i></td>
                                        <td><i className="fas fa-check"></i></td>
                                        <td><i className="fas fa-check"></i></td>
                                        <td></td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                        <div id="ete">
                            <h3>VACANCES D'ÉTÉ</h3>
                            <p>Durant les vacances d'été, le secrétariat enregistre les inscriptions à partir du <strong>19 août 2024</strong> en matinée uniquement.</p>
                            <table className="table table-contact align-center">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Lundi</th>
                                        <th>Mardi</th>
                                        <th>Mercredi</th>
                                        <th>Jeudi</th>
                                        <th>Vendredi</th>
                                    </tr>
                                </thead>
                                <tbody className="body-calendar">
                                    <tr>
                                        <td>08h45 - 12h30</td>
                                        <td><i className="fas fa-check"></i></td>
                                        <td><i className="fas fa-check"></i></td>
                                        <td><i className="fas fa-check"></i></td>
                                        <td><i className="fas fa-check"></i></td>
                                        <td><i className="fas fa-check"></i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div id="map">
                    <iframe ref={myMap} id='g_map' src=" https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2527.506590296966!2d4.015692615871126!3d50.69198307781253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3b220b60b9cc7%3A0x81b95959d74b3155!2sEns+Promo+Sociale+Enghien!5e0!3m2!1sfr!2sbe!4v1480877268176" allowFullScreen></iframe>
             
                    <div id="clickfoot" onClick={(e)=> handleClick(e)}><h3>ITINÉRAIRE À PIED</h3></div>
                    </div>
                </div>
            </div>
        </Fragment>
     );
}
 
export default Contact;